<template>
  <div v-if="!isSearchPage && !isHomePage">
    <component :is="searchComponent" />
  </div>
  <div v-if="isSearchPage && !isLoading" class="ml-2">
    <DropdownCity
      :selected-styles="'rounded-full border border-red-dark pl-2 w-[120px]'"
      :option-styles="'pl-2'"
      :padding-option="'pt-0.5'"
    />
  </div>
  <div class="username-section flex items-center">
    <template v-if="isLoading">
      <div
        class="ph-item mx-2 my-0 overflow-hidden p-0"
        style="width: 120px; height: 20px"
      >
        <div class="ph-col-12 p-0">
          <div class="ph-picture"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <NuxtLink
        v-if="isUserSignedIn"
        class="cursor-pointer border-none bg-white px-4"
      >
        {{ name }}
      </NuxtLink>
      <template v-else>
        <span class="py-3 pl-1">
          <button
            id="navbar-register-button"
            class="cursor-pointer rounded-full border border-gray-500 bg-white px-4 font-bold capitalize"
            @click="onRegister"
          >
            {{ t("register") }}
          </button>
        </span>
        <span class="py-3 pl-1 pr-4">
          <button
            id="navbar-login-button"
            class="cursor-pointer rounded-full border border-red-dark bg-red-dark px-5 text-white"
            @click="onSignInClicked"
          >
            {{ t("signIn") }}
          </button>
        </span>
      </template>
    </template>
  </div>
  <span v-if="isUserSignedIn" class="relative ml-5 mr-4">
    <a class="cursor-pointer text-red-dark" @click.prevent="signOut">{{
      t("signOut")
    }}</a>
  </span>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useModal } from "~/lib/modal";
import { ref, defineAsyncComponent, markRaw, useRoute } from "#imports";
import useClientTypeStore from "~/stores/clientType";
import DropdownCity from "~/components/common/DropdownCity.vue";
import useUserStore from "~/stores/user";
import { isLoading } from "~/services/common/getCities";
import alert from "~/lib/alert";
import { doSignOut } from "~/services/auth/signOut";
const { t } = useI18n({
  useScope: "global",
});

const hhModal = useModal();
const route = useRoute();
const clientTypeStore = useClientTypeStore();
const userStore = useUserStore();
const { isUserSignedIn, name } = storeToRefs(userStore);
const { isMobile } = storeToRefs(clientTypeStore);
const searchComponent = ref(null || {});

function onSignInClicked() {
  hhModal.open("signin-modal");
}

const onRegister = () => {
  hhModal.open("register-modal");
};

function signOut() {
  doSignOut();
  alert.success("Signed Out Successfully");
}

const isHomePage = computed(() => {
  return route.name === "index";
});

const isSearchPage = computed(() => {
  return route.name === "lang-restaurants-search";
});

if (isMobile.value) {
  searchComponent.value = markRaw(
    defineAsyncComponent(() => import("~/section/SearchMobile.vue"))
  );
} else {
  searchComponent.value = markRaw(
    defineAsyncComponent(() => import("~/section/SearchDesktop.vue"))
  );
}
</script>

<script lang="ts">
export default {
  name: "Navigation",
};
</script>
